<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 網頁監控
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <TableTemplate :colums="colums"
                           v-loading="listLoading"
                           :data="tableData"
                           :paginationShow="true"
                           :pageIndex="pageIndex"
                           :pageCount="pageCount"
                           @change-page="changePage">
                <!-- operate -->
                <template v-slot:operate="data">
                    <el-button icon="el-icon-view"
                               size="mini"
                               @click="toPage('projectDetail',data.data,'edit')">瀏覽</el-button>
                    <el-button icon="el-icon-edit"
                               size="mini"
                               @click="toPage('editProject',data.data,'edit')">编辑</el-button>
                </template>
            </TableTemplate>
        </div>
    </div>
</template>

<script>
import TableTemplate from '../../../components/TableTemplate.vue'
import { projectList } from '../../../api/index'
export default {
    components: {
        TableTemplate,
    },
    data() {
        return {
            colums: [
                {
                    name: '專案名稱',
                    prop: 'name',
                },
                {
                    name: '專案排序',
                    prop: 'sort',
                },
                {
                    name: '操作',
                    type: 'slot',
                    attribute: { slotName: 'operate' },
                },
            ],
            tableData: [],
            pageIndex: 1,
            pageCount: 0,
            listLoading: false,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.listLoading = true
            projectList({
                page: this.pageIndex,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.pageCount = res.data.total_page
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.listLoading = false
                })
        },
        toPage(page, info, edit) {
            if (edit == 'edit') {
                this.$router.push({
                    path: page,
                    query: { projectId: info.id },
                })
            } else {
                this.$router.push({ path: page })
            }
        },
        changePage(val) {
            this.pageIndex = val
            this.getData()
        },
    },
}
</script>

<style lang="scss" scoped>
.add-btn {
    padding-bottom: 15px;
}
.red {
    color: #ff0000;
}
</style>