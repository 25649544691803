<template>
    <div>
        <el-table :data="data"
                  @selection-change="handleSelectionChange"
                  :border="isBorder">
            <el-table-column type="selection"
                             v-if="selectionShow">
            </el-table-column>
            <el-table-column v-for="item in colums"
                             :key="item"
                             :prop="item.prop"
                             :label="item.name"
                             :header-align="item.headerAlign ? item.headerAlign : 'center'"
                             :sortable="item.sortable ? item.sortable : false"
                             :width="item.width ? item.width : ''"
                             :align="item.align ? item.align : 'center'">

                <template #default="scope"
                          v-if="item.type && item.type !== ''">
                    <!-- Boolean -->
                    <span v-if="item.type == 'boolean'">
                        {{scope.row[item.attribute.dataFiled] ? item.attribute.trueText:item.attribute.falseText}}
                    </span>
                    <!-- Link -->
                    <span v-if="item.type =='link'&& scope.row[item.attribute.dataFiled] == ''"> - </span>
                    <el-link v-else-if="item.type == 'link'"
                             :type="item.attribute.type ? item.attribute.type : 'primary'"
                             :href="scope.row[item.attribute.dataFiled]"
                             :underline="item.attribute.underline ?item.attribute.underline : false"
                             :target="item.attribute.linkTarget ? item.attribute.linkTarget : '_self'">
                        {{item.attribute.linkText}}
                    </el-link>
                    <!-- Switch -->
                    <div v-else-if="item.type == 'switch'"
                         class="switch-button-area">
                        <div class="switch-button"
                             :class="{'disable':scope.row[item.attribute.dataFiled]}"
                             @click="item.attribute.action(scope.row)">
                            <div class="switch-circle"></div>
                        </div>
                    </div>

                    <!-- 客製化欄位 -->
                    <slot v-else-if="item.type == 'slot'"
                          :name="item.attribute.slotName ? item.attribute.slotName : 'default'"
                          :data="scope.row">
                    </slot>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination"
             v-if="paginationShow">
            <el-pagination background
                           layout=" prev, pager, next"
                           :current-page="pageIndex"
                           :page-count="pageCount"
                           @current-change="handlePageChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tableTemplate',
    props: {
        colums: {
            type: Array,
            default: () => {
                return []
            },
        },
        data: Array,
        isBorder: {
            type: Boolean,
            default: true,
        },
        paginationShow: {
            type: Boolean,
            default: false,
        },
        selectionShow: {
            type: Boolean,
            default: false,
        },
        pageIndex: {
            type: Number,
            default: 1,
        },
        pageCount: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {}
    },
    methods: {
        handlePageChange(val) {
            this.$emit('change-page', val)
        },
        handleSelectionChange(val) {
            this.$emit('selection-change', val)
        },
    },
}
</script>

<style lang="scss" scoped>
.switch-button-area {
    display: flex;
    justify-content: center;
}
.switch-button {
    width: 45px;
    height: 25px;
    padding: 2px 3px;
    border-radius: 50px;
    background: rgb(19, 206, 102);
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    .switch-circle {
        transition-property: right;
        transition-duration: 0.5s;
        position: absolute;
        right: 3px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: white;
    }
}
.switch-button.disable {
    background: #dcdfe6;
    justify-content: flex-start;
    .switch-circle {
        transition-property: right;
        transition-duration: 0.5s;
        right: calc(100% - 23px);
    }
}
</style>